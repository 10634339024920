<template>
  <v-form v-model="valid" ref="form">
    <v-text-field
      class="mr-2"
      label="Praça"
      name="praca"
      placeholder="Praça"
      v-model="praca"
      :rules="pracaRules"
      :counter="50"
      required
    ></v-text-field>
    <v-menu
      class="pr-2"
      ref="bornDate"
      lazy
      :close-on-content-click="false"
      v-model="dataVinc"
      transition="scale-transition"
      offset-y
      full-width
      :nudge-bottom="-22"
      max-width="290px"
      :return-value.sync="dataVinculo"
    >
      <v-text-field
        slot="activator"
        label="Data vínculo praça-funcionário"
        v-model="dataVinculo"
        append-icon="event"
        :rules="dataVincRules"
        readonly
      ></v-text-field>
      <v-date-picker v-model="dataVinculo" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="dataVinc = false">Cancel</v-btn>
        <v-btn flat color="primary" @click="$refs.bornDate.save(dataVinculo)">OK</v-btn>
      </v-date-picker>
    </v-menu>
    <select-funcionario class="mr-2"></select-funcionario>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn outline @click="clear">Limpar</v-btn>
      <router-link :to="{ name: 'Praca' }" class="nounderline">
        <v-btn outline>Voltar</v-btn>
      </router-link>
    </div>
  </v-form>
</template>
<script>
import { ServicoPraca } from "../../servicos/servicoPraca"
import SelectFuncionario from "../funcionario/selectFuncionario.vue"
const servicoPraca = new ServicoPraca()
export default {
  components: {
    SelectFuncionario
  },
  data: () => ({
    id: "",
    praca: "",
    codFuncionario: "",
    dataVinc: "",
    dataVinculo: "",
    pracaRules: [v => !!v || "Nome da praça é obrigatório."],
    dataVincRules: [v => !!v || "É obrigatório informar a data de vínculo entre o funcionário e a praça."],
    valid: true
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.codFuncionario = this.$children[0].$children[2].codFuncionario
        servicoPraca.salvarPraca(this.id, this.praca, this.codFuncionario, this.dataVinculo).then(
          res => {
            if (res.status === 200) {
              this.$router.push({ name: "Praca" })
            }
          },
          // eslint-disable-next-line
          err => console.log(err)
        )
      }
    },
    clear() {
      this.praca = ""
      this.$refs.form.resetValidation()
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoPraca.buscarPorId(this.id).then(res => {
        this.praca = res.data.nomePraca
        this.codFuncionario = res.data.codFuncionario
        this.dataVinculo = res.data.dataVinculo
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
